<template>
  <div>
    <RefundFilter
      v-if="$can('filter', 'ailments')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Refunds" iconTable="fas fa-disease" />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <treeselect
              v-model="perPage"
              :options="perPageOptions"
              :clearable="true"
              class="w-25"
            />
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import axiosAC from "@/core/services/apiInsurance/appointment/refund";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import RefundFilter from "./RefundFilter.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    RefundFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        "id",
        { key: "frontdesks.name", label: "Name", sortable: true },
        { key: "appointments_id", label: "Appointment", sortable: true },
        { key: "value", label: "Value", sortable: true },
        { key: "history", label: "Old value", sortable: true },
        { key: "note", sortable: true },
        { key: "status", label: "Status" },
      ],
      items: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100].map((i) => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      refundFilter: null,
      loading: false
    };
  },
  mounted() {
    this.getRefunds();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getRefunds();
      } else {
        this.searchFilter(this.refundFilter);
      }
    },
  },
  methods: {
    getRefunds() {
      axiosAC
        .appointmentRefundList(this.perPage)
        .then(({registro: { data, current_page, total, per_page, ...res }}) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosAC
          .refundPagination(this.perPage, page)
          .then(({registro: { data, current_page, total, per_page, ...res }}) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosAC
          .refundFilterPagination(this.perPage, page, this.refundFilter)
          .then(({registro: { data, total, current_page, ...res }}) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.refundFilter = value;
        axiosAC
          .refundFilter(this.perPage, value)
          .then(({registro: { data, total, current_page, ...res }}) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getRefunds();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style></style>
