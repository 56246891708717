import { userAxios } from '../index'

const appointmentRefundList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/consultation/refund/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentRefundView = async appoinment => {
  try {
    return await userAxios
      .get(`insurance/consultation/refund/bill/${appoinment}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const refundPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/consultation/refund/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const refundFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/consultation/refund/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const refundFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/consultation/refund/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  appointmentRefundView,
  appointmentRefundList,
  refundPagination,
  refundFilter,
  refundFilterPagination,
}
